/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 26, 2020 */

@font-face {
    font-family: 'product_sansbold';
    src: url('product_sans_bold-webfont.eot');
    src: url('product_sans_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('product_sans_bold-webfont.woff2') format('woff2'),
         url('product_sans_bold-webfont.woff') format('woff'),
         url('product_sans_bold-webfont.ttf') format('truetype'),
         url('product_sans_bold-webfont.svg#product_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'product_sansregular';
    src: url('product_sans_regular-webfont.eot');
    src: url('product_sans_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('product_sans_regular-webfont.woff2') format('woff2'),
         url('product_sans_regular-webfont.woff') format('woff'),
         url('product_sans_regular-webfont.ttf') format('truetype'),
         url('product_sans_regular-webfont.svg#product_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'product_sansblack';
    src: url('productsans-black-webfont.eot');
    src: url('productsans-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('productsans-black-webfont.woff2') format('woff2'),
         url('productsans-black-webfont.woff') format('woff'),
         url('productsans-black-webfont.ttf') format('truetype'),
         url('productsans-black-webfont.svg#product_sans_blackregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'product_sanslight';
    src: url('productsans-light-webfont.eot');
    src: url('productsans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('productsans-light-webfont.woff2') format('woff2'),
         url('productsans-light-webfont.woff') format('woff'),
         url('productsans-light-webfont.ttf') format('truetype'),
         url('productsans-light-webfont.svg#product_sans_lightregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'product_sansmedium';
    src: url('productsans-medium-webfont.eot');
    src: url('productsans-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('productsans-medium-webfont.woff2') format('woff2'),
         url('productsans-medium-webfont.woff') format('woff'),
         url('productsans-medium-webfont.ttf') format('truetype'),
         url('productsans-medium-webfont.svg#product_sans_mediumregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'product_sansthin';
    src: url('productsans-thin-webfont.eot');
    src: url('productsans-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('productsans-thin-webfont.woff2') format('woff2'),
         url('productsans-thin-webfont.woff') format('woff'),
         url('productsans-thin-webfont.ttf') format('truetype'),
         url('productsans-thin-webfont.svg#product_sans_thinregular') format('svg');
    font-weight: normal;
    font-style: normal;
}