@import "./asset/fonts/stylesheet.scss";
@import "./asset/scss/bootstrap.scss";

body,
a,
a:hover {
  color: $dark;
}

body {
  font-size: 15px;
  line-height: 24px;
  font-family: "product_sanslight";
}

/*btn css*/
.btn {
  font-family: "product_sansmedium";
  font-size: 16px;
  line-height: 24px;
  border-width: 2px;
}

.pho_radius_10 {
  @include border-radius(10px);
}

/*fonts css*/
.pho_11_bold {
  font-size: 11px;
  line-height: 16px;
  font-family: "product_sansbold";
}

.pho_12_light {
  font-size: 11px;
  line-height: 19px;
  font-family: "product_sanslight";
}

.pho_12_medium {
  font-size: 11px;
  line-height: 19px;
  font-family: "product_sansmedium";
}

.pho_13_light {
  font-size: 12px;
  line-height: 20px;
  font-family: "product_sanslight";
}

.pho_13_regular {
  font-size: 12px;
  line-height: 20px;
  font-family: "product_sansregular";
}

.pho_14_light {
  font-size: 13px;
  line-height: 24px;
  font-family: "product_sanslight";
}

.pho_14_regular {
  font-size: 13px;
  line-height: 24px;
  font-family: "product_sansregular";
}

.pho_14_medium {
  font-size: 13px;
  line-height: 24px;
  font-family: "product_sansmedium";
}

.pho_14_bold {
  font-size: 13px;
  line-height: 24px;
  font-family: "product_sansbold";
}

.pho_15_light {
  font-size: 14px;
  line-height: 24px;
  font-family: "product_sanslight";
}

.pho_16_light {
  font-size: 15px;
  line-height: 24px;
  font-family: "product_sanslight";
}

.pho_16_regular {
  font-size: 15px;
  line-height: 24px;
  font-family: "product_sansregular";
}

.pho_16_medium {
  font-size: 15px;
  line-height: 24px;
  font-family: "product_sansmedium";
}

.pho_16_bold {
  font-size: 15px;
  line-height: 24px;
  font-family: "product_sansbold";
}

.pho_18_light {
  font-size: 16px;
  line-height: 24px;
  font-family: "product_sanslight";
}

.pho_18_regular {
  font-size: 16px;
  line-height: 24px;
  font-family: "product_sansregular";
}

.pho_18_medium {
  font-size: 16px;
  line-height: 24px;
  font-family: "product_sansmedium";
}

.pho_18_bold {
  font-size: 16px;
  line-height: 24px;
  font-family: "product_sansbold";
}

.pho_20_regular {
  font-size: 18px;
  line-height: 30px;
  font-family: "product_sansregular";
}

.pho_20_bold {
  font-size: 18px;
  line-height: 30px;
  font-family: "product_sansbold";
}

.pho_22_bold {
  font-size: 20px;
  line-height: 32px;
  font-family: "product_sansbold";
}

.pho_24_regular {
  font-size: 21px;
  line-height: 31px;
  font-family: "product_sansregular";
}

.pho_24_medium {
  font-size: 21px;
  line-height: 31px;
  font-family: "product_sansmedium";
}

.pho_24_bold {
  font-size: 21px;
  line-height: 31px;
  font-family: "product_sansbold";
}

.pho_30_bold {
  font-size: 26px;
  line-height: 36px;
  font-family: "product_sansbold";
}

.pho_32_black {
  font-size: 28px;
  line-height: 38px;
  font-weight: 400;
  font-family: "product_sansblack";
}

.pho_34_bold {
  font-size: 30px;
  line-height: 40px;
  font-family: "product_sansbold";

  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 34px;
  }
}

.pho_42_black {
  font-size: 36px;
  line-height: 46px;
  font-family: "product_sansblack";

  @media screen and (max-width: 767px) {
    font-size: 26px;
    line-height: 36px;
  }
}

.pho_48_thin {
  font-size: 42px;
  line-height: 52px;
  font-family: "product_sansthin";
  color: $success;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin border-radius($property) {
  -webkit-border-radius: $property;
  -ms-border-radius: $property;
  border-radius: $property;
}

@mixin box-shadow($property) {
  -webkit-box-shadow: $property;
  -ms-box-shadow: $property;
  box-shadow: $property;
}

@media (min-width: 1281px) {
  .container {
    max-width: 1310px;
  }
}

@media (min-width: 1441px) {
  body.home,
  body.favorites,
  body.HomeOffice,
  footer {
    .container {
      max-width: 1400px;
    }
  }
}

@media (min-width: 1681px) {
  body.home,
  body.favorites,
  body.HomeOffice,
  footer {
    .container {
      max-width: 1630px;
    }
  }
}

.pho_op_4 {
  opacity: 0.4;
}

.pho_op_5 {
  opacity: 0.5;
}

.text-1 {
  color: #666666;
}

.ap_ellips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}

.mt--2 {
  margin-top: -20px;
}

.mt--4 {
  margin-top: -4px;
}

.hover-text-success {
  &:hover {
    color: $success;
  }
}

/*--------------header--------------*/
.pho_logo {
  font-size: 32px;
  line-height: 28px;
  font-family: "product_sansblack";

  @media screen and (max-width: 767px) {
    span {
      display: none;
    }
  }
}

.dropdown-item {
  font-size: 13px;
  line-height: 24px;
  font-family: "product_sansregular";
}

.dropdown-menu {
  border: none;
  @include border-radius(10px);
  @include box-shadow(0 0 16px rgba($black, 0.1));
}

button.pho_user_drpdowwn.dropdown-toggle.btn.btn-transparent {
  background: #f2f2f2;
  @include border-radius(20px);
  margin-left: 10px;
  transition: all 0.3s ease-out;
  text-align: center;
  width: 80px;
  height: 40px;

  &:hover {
    background: #e4e2e2;
  }
}

.pho_lang_drpdowwn {
  background: $white;
  @include border-radius(20px);
  margin-left: 10px;
  transition: all 0.3s ease-out;
  text-align: center;
  width: 80px;
  height: 40px;

  &:hover {
    background: #f2f2f2;
  }
}

.dropdown-toggle::after {
  border: none;
  width: 11px;
  height: 6px;
  vertical-align: 0;
  background: url("./asset/img/pho_arrow_down.svg") no-repeat scroll 0 0 / 100%;
}

header {
  padding: 18px 0;
  border-bottom: 1px solid #e5e5e5;

  @media screen and (max-width: 767px) {
    padding: 8px 0;
  }
}

/*-------footer----*/
footer {
  background: #f5f5f5;

  .pho_menu {
    a {
      color: $dark;
      margin-bottom: 5px;
      display: inline-block;
      transition: all 0.3s ease-in-out;

      &:hover {
        text-decoration: none;
        color: $success;
      }
    }
  }

  .pho_subscribe {
    background: transparent;
    font-size: 13px;
    line-height: 24px;
    font-family: "product_sansregular";
    border-color: #dcdcdc;
    height: 56px;
  }

  .pho_copy {
    font-size: 26px;
    vertical-align: middle;
    margin-bottom: -7px;
    display: inline-block;
  }

  .pho_social {
    li {
      margin-left: 30px;

      a {
        &:hover {
          svg path,
          svg g {
            fill: $green;
            opacity: 1;
          }
        }
      }
    }
  }
}

/*----------------home----------------*/
.pho_bnr {
  height: 635px;
  overflow: hidden;
  width: 100%;
  position: relative;

  @media screen and (max-width: 1366px) {
    height: 545px;
  }

  @media screen and (max-width: 991px) {
    height: 400px;
  }

  .pho_bnr_img {
    height: 100%;
    width: 100%;
    display: block;
  }

  .pho_overlay {
    background: rgba($black, 0.3);
    height: 100%;
    width: 100%;
    padding: 15% 0 20px;
    position: absolute;
    left: 0;
    top: 0;

    @media screen and (max-width: 767px) {
      padding: 25% 0 20px;
    }

    .pho_bnr_text {
      max-width: 726px;
      text-align: center;
      display: table;
      margin: 0 auto;
    }
  }
}

.pho_search_form {
  .pho_serchbtn {
    margin-top: 32px;
    height: 38px;
    svg {
      margin-top: -3px;
    }
  }

  .form-group {
    margin-right: 20px;

    @media screen and (max-width: 767px) {
      margin-right: 2%;
    }

    &:nth-child(1) {
      width: 20%;

      @media screen and (max-width: 1199px) {
        width: 40%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &:nth-child(2) {
      width: 15%;

      @media screen and (max-width: 1199px) {
        width: 25%;
      }

      @media screen and (max-width: 767px) {
        width: 48%;
      }
    }

    &:nth-child(3) {
      width: 15%;

      @media screen and (max-width: 1199px) {
        width: 30%;
      }

      @media screen and (max-width: 767px) {
        width: 48%;
      }
    }

    &:nth-child(4) {
      width: calc(25% - 100px);

      @media screen and (max-width: 1199px) {
        width: 35%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    &:nth-child(5) {
      width: calc(15%);
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.pho_search_tab {
  display: table;
  margin: -120px auto 0;
  width: 100%;
  max-width: 1280px;

  .nav-tabs {
    border-bottom: none;

    .nav-link {
      border: none;
      @include border-radius(10px 10px 0 0);
      background: rgba($black, 0.5);
      color: rgba($white, 0.7);
      font-size: 16px;
      line-height: 37px;
      font-family: "product_sansregular";

      &.active {
        font-family: "product_sansbold";
        color: $black;
        background: $white;
        @include box-shadow(inset 0 0 64px rgba($black, 0.1));
      }
    }
  }

  .tab-content {
    padding: 30px 30px 20px;
    @include border-radius(0 10px 10px 10px);
    @include box-shadow(0 0 64px rgba($black, 0.1));
    background: $white;
  }
}

a:hover {
  text-decoration: none;
}

.pho_link_green {
  transition: all 0.3s ease-in-out;

  svg {
    margin-top: -5px;
  }

  &:hover {
    svg path {
      fill: #03652d;
    }
  }
}

.pho_bx1 {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  a {
    &:hover {
      .pho_img_bx {
        span {
          transform: scale(1.05);
        }
      }
    }

    .pho_img_bx {
      height: 260px;
      width: 100%;
      overflow: hidden;
      @include border-radius(10px);
      position: relative;

      span {
        height: 100%;
        width: 100%;
        display: block;
        transition: all 0.3s ease-out;
      }
    }
  }
}

.pho_star {
  .pho_active {
    svg path {
      fill: $orange;
    }
  }
}

.pho_like_btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 52px;
  height: 40px;
  background: rgba($white, 0.7);
  @include border-radius(0 0 0 50px);
}

.pho_bx2 {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  @include border-radius(10px);

  a {
    &:hover {
      .pho_img_bx {
        span {
          transform: scale(1.05);
        }
      }

      .pho_details {
        .pho_detal_inr {
          svg.text-white {
            opacity: 1;
          }
        }
      }
    }

    .pho_details {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      padding: 20px;
      background: rgba(0, 0, 0, 0);
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(0, 0, 0, 0)),
        color-stop(100%, rgba(0, 0, 0, 0.5))
      );
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);

      .pho_detal_inr {
        display: flex;
        align-items: center;
        width: 100%;

        .pho_left {
          width: calc(100% - 30px);
          display: inline-block;
        }

        svg.text-white {
          opacity: 0;
          display: inline-block;

          path {
            fill: $white;
          }
        }
      }
    }

    .pho_14_medium.pho_white_lbl {
      background: #fff;
      display: inline-block;
      padding: 5px 13px 1px;
      @include border-radius(5px);
      margin-bottom: 5px;
    }

    .pho_img_bx {
      height: 340px;
      width: 100%;
      overflow: hidden;
      @include border-radius(10px);
      position: relative;

      span {
        height: 100%;
        width: 100%;
        display: block;
        transition: all 0.3s ease-out;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .pho_newly_added {
    .col {
      flex: 0 0 33.33%;
    }
  }
}
@media screen and (max-width: 991px) {
  .pho_newly_added {
    .col {
      flex: 0 0 50%;
    }
  }
}

@media screen and (max-width: 767px) {
  .pho_newly_added {
    .col {
      flex: 0 0 100%;
    }
  }
}

.pho_most_srch_link {
  border: none;
  background: none;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  height: 65px;

  svg {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: $green;

    svg {
      opacity: 1;
    }
  }
}

/*------modal form -----*/
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.3;
}

.modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.pho_optional_social {
  background: #f2f2f2;
  padding: 15px;
  margin: 0 -1rem 20px;

  a {
    transition: all 0.3s ease-in-out;
    @include border-radius(28px);
    overflow: hidden;

    &:hover {
      @include box-shadow(0 5px 15px rgba($black, 0.2));
    }
  }
}

.pho_passwordshow {
  position: absolute;
  right: 30px;
  top: 35px;
  padding: 0;
  // background: $white;
}
input[type="password"] {
  padding-right: 60px !important;
}
.form-group {
  margin-bottom: 1rem;
  position: relative;
}

.pho_verification_input {
  input {
    margin-right: 15px;
    width: 46px !important;
    height: 46px;
    text-align: center;
    @media screen and (max-width: 767px) {
      margin-right: 5px;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 1.5rem;
}

.pho_no_suggestions {
  color: #999;
  padding: 0.5rem;
}

.pho_suggestions {
  border: 1px solid #e7e8e8;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
  position: absolute;
  background: #fff;
  z-index: 1;

  li {
    padding: 0.5rem;

    &:hover,
    &.pho_suggestions_active {
      color: $success;
      cursor: pointer;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #e7e8e8;
    }
  }
}

.pho_contrycode {
  max-width: 180px;
  width: 100%;

  .pho__indicators {
    display: none;
  }

  input {
    @include border-radius(0.25rem 0 0 0.25rem);
  }
}

.pho_mnumber {
  @include border-radius(0 0.25rem 0.25rem 0);
}

/*----------------*/
.nav-pills {
  .nav-item {
    border-bottom: 1px solid #e2e2e2;

    .nav-link {
      font-size: 16px;
      font-family: "product_sansregular";
      padding-left: 0;
      padding-right: 0;

      &.active,
      &:hover {
        background: transparent;
        color: $success;
      }
    }
  }
}

/*---------about page--------*/
.pho_bnr_inner_page {
  height: 559px;
  overflow: hidden;
  width: 100%;
  position: relative;

  @media screen and (max-width: 1366px) {
    height: 445px;
  }

  @media screen and (max-width: 991px) {
    height: 300px;
  }

  @media screen and (max-width: 767px) {
    height: 200px;
  }

  .pho_bnr_img {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.pho_about_bx1 {
  border: 2px solid #f2f2f2;
  @include border-radius(10px);
  padding: 30px;
  margin-bottom: 30px;
}

/*----------------How we works------------------------*/
.pho_hww_bx2 {
  border: 2px solid #f2f2f2;
  @include border-radius(10px);
  width: 100%;
  display: flex;
  margin-bottom: 60px;

  img {
    width: 450px;
    height: 100%;
  }

  @media screen and (max-width: 991px) {
    display: unset;

    .p-4 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
}

.h-160 {
  height: 160px;
}

.w-450 {
  max-width: 450px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #666666;
  font-size: 16px;
  font-family: "product_sanslight";
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
  font-size: 16px;
  font-family: "product_sanslight";
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #666666;
  font-size: 16px;
  font-family: "product_sanslight";
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
  font-size: 16px;
  font-family: "product_sanslight";
}

textarea {
  resize: none;
}

.pho_contactpage {
  display: flex;
  margin-top: 72px;

  @media (max-width: 767px) {
    display: block;
  }

  .pho_contact_form_bx {
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;
      padding: 0 15px 30px;
    }

    @media (min-width: 768px) {
      .form {
        width: 345px;
        float: right;
        padding-right: 50px;
      }
    }

    @media (min-width: 992px) {
      .form {
        width: 465px;
        float: right;
        padding-right: 50px;
      }
    }

    @media (min-width: 1200px) {
      .form {
        width: 555px;
        float: right;
        padding-right: 100px;
      }
    }

    @media (min-width: 1281px) {
      .form {
        width: 640px;
        float: right;
        padding-right: 100px;
      }
    }
  }
}

.pho_contact_img {
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
  }
}

/*----------dashboard----------*/
.pho_pt_110 {
  padding-top: 110px;

  @media screen and (max-width: 767px) {
    padding-top: 80px;
  }
}

.pho_dashboard_menu {
  border-bottom: 1px solid #e2e2e2;

  li {
    a {
      position: relative;
      color: #666666;
      padding: 10px 0;
      display: block;
      margin-right: 50px;

      @media screen and (max-width: 1199px) {
        margin-right: 20px;
      }

      &:after {
        display: block;
        width: 100%;
        height: 5px;
        @include border-radius(3px);
        @include box-shadow(0 1px 3px rgba($black, 0.16));
        position: absolute;
        left: 0;
        bottom: -3px;
        background: $green;
      }

      &:hover {
        text-decoration: none;
        color: $black;

        &:after {
          content: "";
        }
      }
    }

    &.pho_active {
      a {
        color: $black;

        &:after {
          content: "";
        }
      }
    }
  }
}

.pho_radius_24 {
  @include border-radius(24px);
}

.pho_tble_btn {
  padding: 0;
  @include border-radius(30px);
  border: 0;
  background: #fff;

  &:hover,
  &:active,
  &:focus {
    background: #fff !important;
    outline: none;
    @include box-shadow(0 1px 5px rgba($black, 0.16) !important);
  }
}

.pho_table {
  border: 2px solid #f2f2f2;
  @include border-radius(15px);
  padding: 0 30px;
  position: relative;

  @media screen and (max-width: 1199px) {
    overflow-x: auto;
  }

  &:before {
    content: "";
    width: 100%;
    height: 48px;
    display: block;
    background: #fbfbfb;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    @media screen and (max-width: 767px) {
      content: none;
    }
  }

  th {
    font-size: 15px;
    line-height: 24px;
    font-family: "product_sansbold";
    background: #fbfbfb;
    border: none !important;

    @media screen and (max-width: 1199px) {
      white-space: nowrap;
    }
  }

  tbody {
    tr:nth-of-type(1) {
      td {
        border: none;
      }
    }
  }

  td {
    font-size: 16px;
    line-height: 24px;
    font-family: "product_sansregular";
    vertical-align: middle;

    @media screen and (max-width: 1199px) {
      white-space: nowrap;
    }
  }

  td:nth-of-type(2) {
    width: 200px;

    @media screen and (max-width: 1280px) {
      width: 160px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  td:nth-of-type(3) {
    width: 200px;

    @media screen and (max-width: 1280px) {
      width: 180px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  td:nth-of-type(4) {
    width: 200px;

    @media screen and (max-width: 1280px) {
      width: 110px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  td:nth-of-type(5) {
    width: 160px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  td:nth-of-type(6) {
    width: 170px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .pho_table {
    .table {
      thead {
        display: none;
      }

      tbody {
        tr {
          border-bottom: 1px solid #e2e2e2;
          padding-bottom: 20px;
          display: block;

          &:last-of-type {
            border: none;
          }
        }

        td {
          display: table;
          margin-top: 30px;
          position: relative;
          white-space: unset;
          border: none;

          &:last-of-type {
            div {
              justify-content: center !important;
            }
          }

          &:before {
            content: attr(data-label);
            font-size: 15px;
            line-height: 24px;
            font-family: "product_sansbold";
            position: absolute;
            left: 0;
            top: -20px;
          }
        }
      }
    }
  }
}

.pho_img_bx_view {
  width: 120px;
  height: 120px;
  @include border-radius(5px);
  overflow: hidden;
  margin-right: 30px;
  display: inline-table;

  span {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.modal-header {
  padding: 1rem 1.5rem;
}

.pho_user_70 {
  img {
    width: 70px;
    height: 70px;
    @include border-radius(35px);
  }
}

.pho_approve_btn {
  margin: 0;
  width: 50%;
  background: rgba($success, 0.1);
  color: $success;
  border: none;
  line-height: 40px;
  @include border-radius(0);

  &:hover {
    background: rgba($success, 1);
    color: $white;
  }
}

.pho_reject_btn {
  margin: 0;
  width: 50%;
  background: rgba(#cc0000, 0.1);
  color: #cc0000;
  border: none;
  line-height: 40px;
  @include border-radius(0);

  &:hover {
    background: rgba(#cc0000, 1);
    color: $white;
  }
}

.btn-outline-secondary {
  border-color: #e2e2e2;
  min-width: 105px;
  text-align: center;

  &:hover,
  &.pho_active,
  &:active,
  &:focus {
    color: $success !important;
    border-color: $success !important;
    background: transparent !important;
    @include box-shadow(none !important);
  }
}

.btn-outline-secondary.dropdown-toggle::after,
.pho_tble_btn.dropdown-toggle::after {
  content: none;
}

.pho_img_bx_view_small {
  vertical-align: middle;

  img,
  .img {
    margin-right: 15px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    @include border-radius(5px);
    width: 80px;
    height: 80px;
  }
}

.pho_table_p_listing {
  tbody {
    td:nth-of-type(1) {
      width: 200px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    td:nth-of-type(2) {
      width: 100px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    td:nth-of-type(4) {
      width: 160px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    td:nth-of-type(5) {
      width: 200px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    td:nth-of-type(6) {
      width: 140px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    td:nth-of-type(7) {
      width: 80px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.btn-outline-secondary.pho_star {
  width: auto;
  min-width: auto;

  &:hover,
  &.pho_active,
  &:active,
  &:focus {
    svg path {
      fill: $success;
    }
  }

  svg path {
    fill: #000000;
  }
}

.text-warning,
.fill-orange {
  path {
    fill: #ff9529;
  }
}

.pho_user_60 {
  img {
    width: 60px;
    height: 60px;
    @include border-radius(35px);
  }
}

.pho_review_bx {
  width: 100%;
  height: 100%;
  padding: 30px 30px 75px;
  border: 2px solid #f2f2f2;
  @include border-radius(15px);
  position: relative;

  .pho_review_footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fbfbfb;
    padding: 13px 30px;
  }
}

.pho_mb_30 {
  margin-bottom: 30px;
}

.pho_message_bx {
  border: 1px solid #eaeaea;
  @include border-radius(15px);
  display: flex;

  @media screen and (max-width: 991px) {
    display: table;
    width: 100%;
    border: none;
    @include border-radius(0);
  }

  .pho_message_chat_bx {
    width: calc(100% - 390px);

    @media screen and (max-width: 991px) {
      width: 100%;
      border: 1px solid #eaeaea;
      @include border-radius(15px);
    }

    @media screen and (max-width: 767px) {
      .pho_scroll_height {
        height: 300px !important;
      }
    }

    .pho_message_header {
      padding: 20px 25px;

      * {
        line-height: 18px;
      }

      &.pho_active {
        .pho_user_60 {
          position: relative;

          &:after {
            content: "";
            width: 16px;
            height: 16px;
            display: inline-block;
            background: $success;
            @include border-radius(50%);
            border: 2px solid $white;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }

    .pho_src_people {
      background: #fbfbfb;
      padding: 0 25px;
      border: 1px solid #eaeaea;
      width: 100%;

      input {
        border: 0;
        padding: 7px 0;
        width: calc(100% - 25px);
        background: transparent;
        font-size: 13px;
        opacity: 0.8;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font-size: 13px;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          font-size: 13px;
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          font-size: 13px;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          font-size: 13px;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }
    }

    .pho_send_recieve_bx {
      padding: 25px;

      .pho_recieve {
        margin-bottom: 20px;

        .pho_chat_bx {
          background: rgba($success, 0.05);
          @include border-radius(15px 15px 15px 0);
          padding: 15px 20px;
          max-width: 60%;
          margin-bottom: 5px;
        }
      }

      .pho_send {
        margin-bottom: 20px;

        .pho_chat_bx {
          background: $success;
          @include border-radius(15px 15px 0 15px);
          padding: 15px 20px;
          max-width: 60%;
          margin-bottom: 5px;
        }
      }
    }
  }

  .pho_user_list_bx {
    width: 390px;
    border-right: 1px solid #eaeaea;

    @media screen and (max-width: 991px) {
      width: 100%;
      border: 1px solid #eaeaea;
      @include border-radius(15px);
      margin-bottom: 30px;
    }

    .pho_message_header {
      padding: 20px 25px;

      .pho_user_60 {
        position: relative;

        &:after {
          content: "";
          width: 16px;
          height: 16px;
          display: inline-block;
          background: $success;
          @include border-radius(50%);
          border: 2px solid $white;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }

    .pho_scroll_height {
      @media screen and (max-width: 991px) {
        height: 300px !important;
      }

      li {
        border-bottom: 1px solid #eaeaea;
        padding: 9px 25px;
        cursor: pointer;

        &.pho_active,
        &:hover {
          background: #f2fcf8;
        }

        &.pho_online {
          .pho_user_60 {
            position: relative;

            &:after {
              content: "";
              width: 16px;
              height: 16px;
              display: inline-block;
              background: $success;
              @include border-radius(50%);
              border: 2px solid $white;
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }
      }
    }

    .pho_src_people {
      background: #fbfbfb;
      padding: 0 25px;
      border: 1px solid #eaeaea;
      width: 100%;

      input {
        border: 0;
        padding: 7px 0;
        width: calc(100% - 25px);
        background: transparent;
        font-size: 13px;
        opacity: 0.8;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font-size: 13px;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          font-size: 13px;
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          font-size: 13px;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          font-size: 13px;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
}

.text-secondary {
  path {
    fill: #a9a9a9;
  }
}

.pho_chat_field_bx {
  padding: 14px 25px;
  border-top: 1px solid #eaeaea;

  @media screen and (max-width: 767px) {
    padding-top: 70px;
    position: relative;
  }

  .pho_chat_field {
    position: relative;
    width: 100%;

    @media screen and (max-width: 767px) {
      position: absolute;
      top: 10px;
      left: 25px;
      width: calc(100% - 50px);
      margin: 0 !important;
    }

    .pho_chat_input {
      width: 100%;
      background: #f8f8f8;
      @include border-radius(25px);
      border: none;
      padding: 13px 70px 13px 25px;

      &:focus {
        border: none;
        outline: none;
      }
    }

    .pho_tble_btn {
      position: absolute;
      top: 5px;
      right: 6px;
    }
  }
}

.dropdown-menu[x-placement^="bottom-end"] {
  left: auto !important;
  right: 0 !important;
  transform: translate(0, 52px) !important;
}

/*-----------------CreateYourListing--------------------*/
.pho_CreateYourListing_main {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}

.pho_step_nav {
  position: relative;
  margin: 20px 0 40px;
  overflow: hidden;

  &:after {
    content: "";
    width: 100%;
    height: 0;
    border-top: 2px dashed rgba($black, 0.2);
    position: absolute;
    left: 0;
    top: 23px;
    z-index: -1;
  }

  li {
    border: 2px solid #cccccc;
    @include border-radius(50%);
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    color: #cccccc;
    background: $white;
    z-index: 1;
    cursor: pointer;

    &.done {
      background: $green;
      color: $white;
      border-color: $green;
      position: relative;
      z-index: 1;

      &:last-child {
        &:after {
          content: none;
        }
      }

      &:after {
        content: "";
        width: calc(850px / 6);
        height: 2px;
        background: $green;
        display: block;
        position: absolute;
        left: 34px;
        top: 21px;
        z-index: 0;
      }
    }

    &.doing {
      color: $black;
      border-color: $green;
    }
  }
}

/*----------------------------------*/
.pho_stepBox {
  background: $white;
  @include border-radius(15px);
  @include box-shadow(0 0 16px rgba($black, 0.1));
  min-height: 580px;

  .pho_step_header {
    border-bottom: 2px solid rgba($black, 0.05);
    padding: 20px 50px;
    min-height: 90px;
    @media screen and (max-width: 767px) {
      padding: 15px;
    }
  }

  .pho_checkbox_main {
    padding-top: 24%;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }

    .form-check {
      position: relative;
      padding: 0;
      margin: 0 20px;
      @media screen and (max-width: 767px) {
        margin: 20px 0;
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;
        opacity: 0;
        cursor: pointer;

        &:hover + .form-check-label,
        &:checked + .form-check-label {
          border-color: $green;
          color: $green;
        }
      }

      .form-check-label {
        border: 1px solid #efefef;
        @include border-radius(10px);
        font-size: 16px;
        line-height: 62px;
        font-family: "product_sansmedium";
        width: 206px;
        text-align: center;
      }
    }
  }

  .pho_step_form {
    padding: 50px;
    @media screen and (max-width: 767px) {
      padding: 15px;
    }
  }

  /*--------------------------------*/
  .form-file.pho_file1 {
    position: relative;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;
    }

    &::before {
      content: "Or";
      font-size: 16px;
      font-weight: 600;
      position: absolute;
      top: 10px;
      left: 20px;
    }

    label.form-file-label {
      background: #f2f2f2;
      width: 100%;
      text-align: center;
      padding: 10px;
      font-size: 16px;
      font-weight: 600;
      color: $success;
    }
  }

  .btn-outline-secondary:not(:disabled):not(.disabled) {
    font-size: 15px;
    line-height: 24px;
    font-family: "product_sansmedium";
    border-color: #efefef;

    &.active {
      background-color: $green;
      border-color: $green;
    }
  }

  .pho_hourly_bx {
    .form-check {
      width: 133px;
    }

    .input-group,
    .react-datepicker-wrapper {
      width: 165px;

      .form-control {
        border-color: #efefef;
        font-size: 16px;
        line-height: 20px;
        font-family: "product_sansmedium";
      }

      select {
        background: #efefef;
        font-size: 16px;
        line-height: 20px;
        font-family: "product_sansmedium";
        @include border-radius(0 0.25rem 0.25rem 0);
        color: rgba($black, 0.5);
        width: 62px;
        padding: 0 6px;
      }
    }
  }

  .pho_totalHours {
    .form-control {
      padding: 1.5rem 1rem;
      border-color: #efefef;
    }

    .input-group-text {
      border-color: #efefef;
      background: #efefef;
      font-size: 16px;
      line-height: 20px;
      font-family: "product_sansmedium";
      @include border-radius(0 0.25rem 0.25rem 0);
      color: rgba($black, 0.5);
    }
  }
}

.form-check.pho_custom_radio_btn {
  position: relative;
  padding: 0;
  display: inline-block;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;

    &:checked + .form-check-label {
      &:before {
        background: $green;
        border-color: $green;
      }
    }
  }

  .form-check-label {
    position: relative;
    padding: 0 0 0 30px;
    font-size: 16px;
    line-height: 20px;
    font-family: "product_sansregular";

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      @include border-radius(5px);
      display: block;
      border: 2px solid #efefef;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:after {
      content: "";
      width: 12px;
      height: 6px;
      display: block;
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      @include transform(translate(4px, 5px) rotate(-45deg));
    }
  }
}

.pho_availability {
  .form-check {
    position: relative;
    padding: 0;

    &:first-child {
      .form-check-label {
        @include border-radius(10px 0 0 10px);
      }
    }

    &:last-child {
      .form-check-label {
        @include border-radius(0 10px 10px 0);
      }
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      opacity: 0;
      cursor: pointer;

      &:hover + .form-check-label,
      &:checked + .form-check-label {
        border-color: $green;
        color: $white;
        background: $green;
      }
    }

    .form-check-label {
      border: 1px solid #efefef;
      font-size: 16px;
      line-height: 42px;
      font-family: "product_sansmedium";
      width: 100px;
      text-align: center;
    }
  }
}

/*--------------------------------------------*/
.pho_plain_btn {
  padding: 0;
  border: none;
  background: none;
}

.pho_category_box {
  position: relative;
  width: 90px;
  height: 100px;
  margin: 0 12px 40px 0;

  input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;

    &:checked + label,
    &:hover + label {
      .text {
        border-color: $green;
        background: $green;
        color: $white;
      }

      .pho_count {
        display: flex;
      }
    }
  }

  label {
    margin-bottom: 0;

    .text {
      width: 90px;
      height: 100px;
      line-height: 130%;
      border: 2px solid #efefef;
      @include border-radius(5px);
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($black, 0.5);
    }

    .pho_count {
      position: absolute;
      bottom: -30px;
      left: 5px;
      display: none;

      input {
        width: 20px;
        margin: 0 6px;
        border: 0;
        padding: 0;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        font-family: "product_sansmedium";
      }
    }
  }
}

button:focus,
button:active {
  @include box-shadow(none !important);
  outline: none !important;
}

.pho_step3 {
  .input-group {
    .form-control {
      border-color: #efefef;
      color: rgba($black, 1);
    }
  }

  .form-control {
    border-color: #efefef;
    color: rgba($black, 0.5);
  }

  .input-group-text {
    border-color: #efefef;
    background: #efefef;
    font-size: 16px;
    line-height: 20px;
    font-family: "product_sansmedium";
    @include border-radius(0 0.25rem 0.25rem 0);
    color: rgba($black, 0.5);
  }
}

.pho_lbl_width {
  width: 115px;
  margin: 0 15px 0 0;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.pho_control_width {
  width: calc(100% - 130px);

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

p.pin-text {
  background: #fff;
  display: block;
  width: 130px;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  line-height: 140%;
}

.slick-next:before,
.slick-prev:before {
  color: #05af4e;
}

.pho_slider1 {
  width: 100%;
  height: 260px;
  @include border-radius(5px);
  overflow: hidden;
  margin-bottom: 10px;

  .pho_slide {
    width: 100%;
    height: 260px;
    padding: 0;

    img {
      width: 100%;
      height: 260px;
      border: 1px solid #efefef;
    }
  }
}

.pho_slider2 {
  width: 100%;
  height: 80px;

  .slick-list {
    margin: 0 -5px;
  }

  .pho_slide {
    height: 80px;
    padding: 0 5px;

    img {
      width: 100%;
      height: 80px;
      @include border-radius(5px);
      overflow: hidden;
      border: 1px solid #efefef;
    }
  }
}

.form-file.pho_file2 {
  position: relative;
  display: inline-block;
  margin: 10px 0;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }

  label.form-file-label {
    background: $white;
    border: 2px solid $success;
    color: $success;
    @include border-radius(5px);
    width: 92px;
    text-align: center;
    font-size: 16px;
    line-height: 37px;
    font-family: "product_sansmedium";
    margin: 0;
  }
}

/*-------------------favorites----------------------*/
.favorites.nav-tabs {
  border: none;
  margin-bottom: 50px;

  a.nav-link {
    border: 1px solid #e2e2e2;
    font-size: 13px;
    line-height: 24px;
    font-family: "product_sanslight";
    background: $white;
    color: #222222;
    @include border-radius(20px);
    margin-right: 10px;
    width: 109px;
    text-align: center;

    &.active,
    &:hover {
      font-family: "product_sansmedium";
      background: #e2e2e2;
    }
  }
}

.pho_add_by {
  span {
    width: auto;
    max-width: 50%;
    line-height: 100%;

    &:first-child {
      border-right: 1px solid #b7b7b7;
      padding-right: 10px;
      margin-right: 10px;
    }
  }
}

.pho_prize_details {
  @include border-radius(15px);
  @include box-shadow(0 0 6px #e2e2e2);
  padding: 30px;

  .pl-4 {
    width: 100%;
    overflow: hidden;
  }

  .p_img {
    @include border-radius(5px);
  }
}

.pho__option,
.pho__single-value {
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: "product_sansmedium" !important;
}

.pho__option--is-focused,
.pho__option--is-selected {
  background: $success !important;
  color: $white !important;
}

.pho__control--is-focused {
  border-color: $success !important;
  @include box-shadow(none !important);
}

.pho__control {
  background: transparent !important;
  border: none !important;
}

/*---------------------------------------------------*/
.pagination {
  .page-item {
    &.active {
      .page-link {
        background: $success;
        color: $white;
      }
    }

    .page-link {
      font-size: 18px;
      font-family: "product_sansregular";
      color: $black;
      border: none;
      @include border-radius(50%);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      margin-left: 5px;
    }

    &.prev,
    &.next {
      .page-link {
        border: 1px solid $black;
        opacity: 0.2;
        font-size: 30px;
        &:hover {
          opacity: 1;
          border-color: #e9ecef;
        }
      }
    }
  }
}

.page-link:focus {
  @include box-shadow(none);
}

.pho_box_map > div {
  display: flex;
  flex-wrap: wrap;
}

.pho_pin_dots {
  width: 10px;
  height: 10px;
  display: inline-block;
  @include border-radius(50%);
  background: $success;
}

.pho_pin {
  @include transform(translate(-15px, -40px));
}

.pho_map_btn {
  width: 120px;
  height: 41px;
  text-align: right;
  padding: 0;
  line-height: 25px;

  &:hover {
    color: $success !important;
    text-decoration: none;
  }
}

.pho_filter,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  background: #f2f2f2 !important;
  border-color: #f2f2f2 !important;
  color: #000000 !important;
}

.pho_map {
  @include border-radius(10px);
  overflow: hidden;
  min-height: 300px;
}

.pho_width_250 {
  width: 250px;
}

.pho_conatct {
  width: calc(100% - 123px);
}

.todo {
  pointer-events: none;
}

.pho_dropdown_checkbox {
  .pho_custom_radio_btn {
    width: 100%;
  }
}

.text-success {
  path {
    fill: $success;
  }
}

.pho_drp_address {
  z-index: 4;

  .dropdown-toggle {
    width: 157px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-right: -50px;
    @media screen and (max-width: 767px) {
      margin-right: 0.5rem;
    }
  }
}

.pho_drp_date {
  z-index: 3;
  position: relative;
  display: inline-table;
  vertical-align: middle;

  .dropdown-toggle {
    width: 156px;
    padding: 0.375rem 0.75rem 0.375rem 50px !important;
    display: inline-block;
    margin-right: -50px;
    border: 2px solid #e2e2e2;
    background: $white;
    @media screen and (max-width: 767px) {
      margin-right: 0.5rem;
      width: 160px;
      padding-left: 15px !important;
    }
    &:focus {
      background: #f2f2f2;
      border: 2px solid #f2f2f2;
    }
  }
}

.pho_drp_day {
  z-index: 2;
  display: inline-block;
  top: -3px;
  &.homeoffice {
    @media screen and (max-width: 767px) {
      top: -3px;
    }
  }
  @media screen and (max-width: 767px) {
    top: 0;
  }

  .phoSelect__menu {
    text-align: left;
  }
  .phoSelect__control {
    border: 2px solid #e2e2e2 !important;
    &.phoSelect__control--is-focused {
      border: 2px solid #f2f2f2 !important;
      background: #f2f2f2;
    }
  }
  .dropdown-toggle,
  .phoSelect__control {
    width: 166px;
    padding-left: 50px !important;
    display: inline-flex;
    margin-right: -50px;
    @include border-radius(24px);
    @media screen and (max-width: 767px) {
      margin-right: 0.5rem;
      width: 160px;
      padding-left: 15px !important;
    }
  }
}

.pho_drp_guest {
  z-index: 1;
  display: inline-block;
  top: -3px;
  @media screen and (max-width: 767px) {
    top: 0;
  }

  &.input {
    top: 0;
  }

  .phoSelect__menu {
    text-align: left;
  }
  .phoSelect__control {
    border: 2px solid #e2e2e2 !important;
    &.phoSelect__control--is-focused {
      border: 2px solid #f2f2f2 !important;
      background: #f2f2f2;
    }
  }
  .dropdown-toggle,
  .phoSelect__control {
    width: 166px;
    padding-left: 50px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    background: $white;
    @include border-radius(24px);
    @media screen and (max-width: 767px) {
      width: 160px;
      padding-left: 15px !important;
      margin-right: 0.5rem;
    }
  }
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
  background: $white !important;
}

.btn-link,
.dropdown-item {
  &:active {
    background: transparent;
    color: $black;
  }

  &:focus {
    outline: none;
    color: $black;
  }
}

/*-----------------------Account------------------------*/
.pho_profileMenuBox {
  @include border-radius(30px);
  padding: 30px 30px 10px;
  background: $white;
  @include box-shadow(0 0 16px rgba($black, 0.1));

  .pho_userPic {
    position: relative;
    display: table;
    margin: 0 auto;

    img {
      width: 145px;
      height: 145px;
      @include border-radius(50%);
      @include box-shadow(0 3px 6px rgba($black, 0.16));
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }
}

.pho_profileMenuList {
  li {
    border-bottom: 1px solid #e2e2e2;
    font-size: 18px;
    line-height: 24px;
    padding: 20px 0;
    position: relative;
    cursor: pointer;
    font-family: "product_sansregular";

    &:hover,
    &.active {
      color: $success;

      svg path {
        stroke: $success;
      }
    }

    svg {
      @include transform(rotate(-90deg));
    }
  }
}

.pho_account_hdr {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 30px;
  @media screen and (max-width: 991px) {
    margin-top: 30px;
  }
}

.grayField {
  background: #f8f8f8;
}

.pho_uploadDoc {
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}

.pho_document {
  width: 260px;
  height: 160px;
}

.pho_removePic {
  display: table;
  margin: 0 auto 20px;
}

.pho_payment_bx {
  li {
    margin-bottom: 30px;

    .bx {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 2px solid #f2f2f2;
      @include border-radius(10px);
      padding: 25px;

      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          margin-right: 15px;
        }
      }
    }
  }
}

.pho_notiBx {
  li {
    border: 2px solid #f2f2f2;
    @include border-radius(8px);
    padding: 25px;
    margin-bottom: 20px;

    &:hover {
      border-color: rgba($success, 0.3);
    }
  }
}

.pho_userPicAdd {
  margin: 0 auto 30px;
  display: table;
  @include border-radius(10px);
  overflow: hidden;
  position: relative;

  img {
    width: 90px;
    height: 90px;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}

.pho_newly_added > .col {
  max-width: 20%;
  @media screen and (max-width: 1199px) {
    max-width: 33.33%;
  }
  @media screen and (max-width: 991px) {
    max-width: 50%;
  }
  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
}

.pho_editBtn {
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    left: 0%;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

/*--------------details page---------------*/
.pho_detailsBNR {
  height: 609px;
  width: 100%;
  display: flex;
  @include border-radius(12px);
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 1279px) {
    height: 440px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }

  .phoShowSlideShowBtn {
    position: absolute;
    right: 30px;
    bottom: 30px;
    background: $white;
    font-size: 13px;
    line-height: 24px;
    font-family: "product_sansmedium";
    @include border-radius(5px);
    @include box-shadow(0 3px 15px rba(#555555, 0.16));
    padding: 5px 13px 0;
    color: $black;
    border: none;

    &:hover,
    &:active {
      background: $green;
      color: $white;
    }
  }

  .pho_img_bx {
    width: 100%;
    height: 100%;

    span {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .left {
    width: 50%;
    height: 100%;
    padding-right: 5px;
  }

  .right {
    width: 50%;
    height: 100%;
    padding-left: 5px;
    display: flex;
    flex-wrap: wrap;

    .leftInRight {
      width: 50%;
      height: 50%;
      padding: 5px 5px 5px 0;
    }

    .rightInRight {
      width: 50%;
      height: 50%;
      padding: 5px 0 5px 5px;
    }
  }
}

.pho_slideShow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: 2222;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.mobile {
    display: none;
    height: 150px;
    width: 100%;

    .pho_img_bx {
      width: 100% !important;
      height: 150px !important;

      span {
        width: 100%;
        height: 100%;
        display: block;
      }

      @media screen and (max-width: 1440px) {
        width: 960px;
        height: 450px;
      }
    }

    @media screen and (max-width: 767px) {
      display: flex;
    }
  }

  @media screen and (max-width: 767px) {
    position: static;
    width: 100%;
    height: 100px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 30px;
  }

  .slick-prev {
    left: 10px;
    z-index: 22;
    width: 30px;
    height: 30px;
  }

  .slick-next {
    right: 10px;
    z-index: 22;
    width: 30px;
    height: 30px;
  }

  .pho_img_bx {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    height: 600px;

    span {
      width: 100%;
      height: 100%;
      display: block;
    }

    @media screen and (max-width: 1440px) {
      width: 960px;
      height: 450px;
    }
  }
}

body.openSlideShow {
  overflow: hidden;
}

.phoCloseSlideShowBtn {
  cursor: pointer;
  background: rgba(34, 34, 34, 0.1);
  border: none;
  color: rgb(34, 34, 34);
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 222;
}

.pho_detailMark {
  background: $white;
  @include border-radius(5px);
  border: 1px solid #bcbcbc;
  padding: 5px 13px 2px;
  font-size: 13px;
  line-height: 17px;
  font-family: "product_sansmedium";
  display: inline-block;
  vertical-align: middle;
}

.phoFavoriteBtn {
  font-size: 16px;
  line-height: 24px;
  font-family: "product_sansregular";
  display: flex;
  align-items: center;
  width: auto;
  position: unset;

  &:hover {
    color: $green;

    svg path {
      fill: $green;
    }
  }

  svg {
    margin-right: 10px;
  }
}

.readMoreText {
  font-size: 16px;
  line-height: 24px;
  font-family: "product_sansregular";
  color: $black !important;
}

.pho_CostPlaceBox {
  border: 1px solid #e2e2e2;
  @include border-radius(10px);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .pho_count > span {
    width: 16px;
    margin: 0 5px;
    display: inline-block;
    text-align: center;
  }
}

.phoViewAllAmenitiesbtn {
  @include border-radius(20px);
  border: 1px solid #f2f2f2;
  font-size: 13px;
  line-height: 24px;
  font-family: "product_sansmedium";
  color: $black;
}

.phoBookFormBox {
  position: sticky;
  top: 90px;
  z-index: 1020;
  background: $white;
  @include box-shadow(0 0 16px rgba($black, 0.1));
  @include border-radius(15px);
  padding: 40px;
  max-width: 550px;
}

.react-datepicker__day--selected.react-datepicker__day--today,
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $green !important;
}

input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.phoReviewsBtn {
  @include border-radius(20px);
  color: $black;
}

.addStarRettingIcon {
  width: 40px;
  height: 40px;
  margin: 0 5px;
}

.phoDetailsMap > div {
  height: 500px !important;

  @media screen and (max-width: 767px) {
    height: 300px !important;
  }
}

.phoDetailsMap {
  @include border-radius(15px);
  overflow: hidden;
}

.phoSimilarSlider {
  button.slick-arrow.slick-next {
    top: -30px;
    right: 0px;
    width: 30px;
    height: 30px;
    @include border-radius(50%);
    background: url("./asset/img/pho_next.svg") no-repeat scroll 0 0;

    @media screen and (max-width: 767px) {
      display: none !important;
    }

    @media screen and (max-width: 767px) {
      display: none !important;
    }

    &:hover {
      background: url("./asset/img/pho_next_hover.svg") no-repeat scroll 0 0;
      @include box-shadow(0 5px 6px rgba(#05af4e, 0.16));
    }

    &:before {
      content: none;
    }
  }

  button.slick-arrow.slick-prev {
    top: -30px;
    right: 40px;
    left: auto;
    width: 30px;
    height: 30px;
    @include border-radius(50%);
    background: url("./asset/img/pho_prev.svg") no-repeat scroll 0 0;

    @media screen and (max-width: 767px) {
      display: none !important;
    }

    @media screen and (max-width: 767px) {
      display: none !important;
    }

    &:hover {
      background: url("./asset/img/pho_prev_hover.svg") no-repeat scroll 0 0;
      @include box-shadow(0 5px 6px rgba(#05af4e, 0.16));
    }

    &:before {
      content: none;
    }
  }

  .slick-disabled {
    opacity: 0.5;
  }

  .slick-dots {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .pagingInfo {
    margin-right: 100px;
  }
}

.pho_fileBox {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  border: 1px solid #e7e8e8;
  @include border-radius(0.25rem);
  height: 86px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: calc(100% - 40px);
  }
  button {
    font-size: 16px;
    color: $black;
    cursor: pointer;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
.pho_minHeight {
  min-height: calc(100vh - 300px);
}
.w-100px {
  width: 100px;
}
@media screen and (max-width: 767px) {
  .text-right.col-lg-8 {
    text-align: left !important;
  }
  [id*="left-tabs-tabpane-"] {
    padding-top: 30px;
  }
  .pho_gid {
    justify-content: center;
  }
  .flex-wrap-mobile {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .pho_step_form .col-md-10 > .row > .col {
    flex-basis: auto;
  }
  .pho_hourly_bx {
    .d-flex {
      flex-direction: column;
      .pho_custom_radio_btn {
        width: auto;
      }
    }
  }
}
.w-90px {
  width: 90px;
}
.phoFileBox {
  border: 2px solid #f2f2f2;
  @include border-radius(5px);
  padding: 10px;
  display: inline-flex;
  align-items: center;
  margin: 20px 0;
}
input:active {
  background: $success;
}
.phoSelect__indicator-separator {
  display: none;
}
.phoSelect__control {
  border: 1px solid #e7e8e8 !important;
  @include box-shadow(none!important);
}
.phoSelect__control--is-focused {
  border: 1px solid $green !important;
  @include box-shadow(none!important);
}
.phoDot {
  display: block;
  width: 3px;
  height: 3px;
  @include border-radius(50%);
  background: $black;
  margin: -3px 5px 0;
}
.phoSearchSelect.phoSelect__control {
  background: #f2f2f2;
  border-color: #f2f2f2 !important;
  @include border-radius(20px);
  width: 125px;
}
.phoSearchSelect.phoSelect__value-container {
  padding: 3px 1rem;
}
.pho_custom_radio_btn.form-check {
  width: 100%;
}
*:focus {
  outline: none;
}
.input-range__slider {
  background: $green !important;
  border-color: $green !important;
}
.input-range__track--active {
  background: $green !important;
}
.reviewDropdown {
  width: 140px !important;
  text-align: left;
  .phoSearchSelect.phoSelect__control {
    width: auto;
  }
}
.pho_deleteImg {
  position: absolute;
  top: 5px;
  right: 10px;
  line-height: 6px;
  padding: 5px;
  background: $white;
  @include border-radius(50%);
  &:active {
    background: $white;
  }
}
.pho_slider1 .pho_deleteImg {
  display: none;
}
.pho_14_medium::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
}
.pho_14_medium::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px;
}
.pho_14_medium:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px;
}
.pho_14_medium:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px;
}
#ic_check_24px {
  fill: $white;
}
.pho_top-left {
  position: absolute;
  left: 10px;
  top: 10px;
  height: auto !important;
  width: auto !important;
}
.pho_colorLightGray {
  color: #6a6a6a;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box,
.react-datepicker__time-container {
  width: 88px !important;
}
.input-range__track.input-range__track--background
  span.input-range__slider-container:nth-child(2)
  span.input-range__label.input-range__label--value,
.input-range__track.input-range__track--background
  span.input-range__slider-container:nth-child(3)
  span.input-range__label.input-range__label--value {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.input-range__track.input-range__track--background
  span.input-range__slider-container:nth-child(2):hover
  span.input-range__label.input-range__label--value,
.input-range__track.input-range__track--background
  span.input-range__slider-container:nth-child(3):hover
  span.input-range__label.input-range__label--value {
  opacity: 1;
}
.btn-light-success {
  background: rgba($success, 0.1);
  color: $success;
  border: 1px solid #e7e8e8;
  border-left: none;
}
.phoTopRight {
  position: absolute;
  top: 10px;
  right: 10px;
}
.p-relative {
  position: relative;
}
.remove-btn-bg-color {
  background: none;
  border: none;
}
.remove-btn-bg-color:hover {
  background: none;
  border: none;
}
button.mx-2.remove-btn-bg-color.btn.btn-primary:not(:disabled):not(.disabled):active {
  background: none !important;
  border: none !important;
}

.datapickerModal {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 320px;
    }
  }
  .DayPicker {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: $success;
  }
  .DayPicker-Day {
    line-height: 19px;
  }
}
.pro_Admin_data {
  a {
    font-family: "product_sansbold";
  }
}
.aboutImg {
  height: 100%;
  min-height: 220px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.TSImg {
  min-height: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
